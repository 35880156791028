import { createApi } from "@reduxjs/toolkit/query/react";

import type {
  AudDimWeightSummary,
  AudMarginSummary,
  AudPendingSummaryItem,
  AudResidentialSummary,
  SummaryGroupBy,
} from "./audit-service.types";
import { default as baseQuery } from "./baseQuery";

export const reducerPath = "audit-api";

export const baseAuditApi = createApi({
  reducerPath,
  baseQuery,
  endpoints: () => ({}),
});

export const auditApi = baseAuditApi.injectEndpoints({
  endpoints: (builder) => ({
    dashboardMarginSummary: builder.query<
      AudMarginSummary[],
      { groupBy: SummaryGroupBy; toDate: string; fromDate: string }
    >({
      query: ({ groupBy, ...params }) => ({
        url: `/audDashboard/margin/${groupBy}`,
        params,
      }),
    }),
    dashboardDimWeightSummary: builder.query<
      AudDimWeightSummary[],
      {
        groupBy: SummaryGroupBy;
        toDate: string;
        fromDate: string;
      }
    >({
      query: ({ groupBy, ...params }) => ({
        url: `/audDashboard/dimWeight/${groupBy}`,
        params,
      }),
    }),
    dashboardResidentialSummary: builder.query<
      AudResidentialSummary[],
      { groupBy: SummaryGroupBy; toDate: string; fromDate: string }
    >({
      query: ({ groupBy, ...params }) => ({
        url: `/audDashboard/residential/${groupBy}`,
        params,
      }),
    }),
    dashboardPendingSummary: builder.query<
      AudPendingSummaryItem[],
      { toDate: string; fromDate: string }
    >({
      query: (params) => ({
        url: `/audDashboard/pending`,
        params,
      }),
    }),
  }),
});
