import { Button, Center, VStack, Heading } from "@chakra-ui/react";

import { useAuth } from "../../hooks";

export default function Login() {
  const {
    loginWithRedirect,
    isAuthenticated,
    logout,
    isLoading: isAuthenticating,
  } = useAuth();

  const redirectUri = `${window.location.origin}/dashboard`;

  const handleLogout = () => logout();

  const handleLogin = () => {
    return loginWithRedirect({
      redirectUri,
    }).catch((reason) => {
      console.error(reason);
      alert(JSON.stringify(reason));
    });
  };

  return (
    <Center h="100vh">
      <VStack>
        <Heading size="xl" pb={12}>
          Please log in
        </Heading>
        <Button
          variant="primary"
          size="sm"
          mr={4}
          onClick={isAuthenticated ? handleLogout : handleLogin}
          isLoading={isAuthenticating}
        >
          {isAuthenticated ? "Logout" : "Login"}
        </Button>
      </VStack>
    </Center>
  );
}
