import { auditApi as baseAuditApi } from "./base.api";

export const TAG_TYPES = [];

// Setting this up the same way as generated versions work, for when that happens.
export const auditApi = baseAuditApi.enhanceEndpoints({
  addTagTypes: TAG_TYPES,
  endpoints: {
    // accessorialsIndex: {
    //   providesTags: ["Accessorials"],
    // },
    // accessorialsCreate: {
    //   invalidatesTags: (_result, error) => (!error ? ["Accessorials"] : []),
    // },
    // accessorialsUpdate: {
    //   invalidatesTags: (_result, error) => (!error ? ["Accessorials"] : []),
    // },
    // accessorialsDelete: {
    //   invalidatesTags: (_result, error) => (!error ? ["Accessorials"] : []),
    // },
    // billingAccountsIndex: {
    //   providesTags: ["BillingAccounts"],
    // },
  },
});

export type AuditApi = typeof auditApi;
