import { type EnvConfig } from "./api";

import type { RootState } from ".";
import { isBaseError } from "@stordco/fe-components";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { z } from "zod";

/**
 * If either :// or // is present consider it to be an absolute url
 *
 * @param url string
 */

export function isAbsoluteUrl(url: string) {
  return new RegExp("(^|:)//").test(url);
}

export const fetchStaticConfig = async () =>
  fetch("/config.json").then((r) => r.json() as Promise<EnvConfig>);

export const buildAuth0ClientOptions = (config: RootState["config"]) => ({
  domain: config.AUTH0_DOMAIN,
  client_id: config.AUTH0_CLIENT_ID,
});

export const hasSpexValidationErrors = (
  error: unknown,
): error is FetchBaseQueryError & {
  data: z.infer<typeof spexValidationErrorResponseSchema>;
} => {
  return (
    isBaseError(error) &&
    error.status === 422 &&
    "errors" in (error.data as any) &&
    spexValidationErrorResponseSchema.safeParse(error.data).success
  );
};

export const hasEctoFormValidationErrors = <T extends string>(
  error: unknown,
): error is FetchBaseQueryError & { data: { errors: Record<T, string[]> } } => {
  //
  return (
    isBaseError(error) &&
    error.status === 422 &&
    "errors" in (error.data as any) &&
    ectoErrorSchemaFormat.safeParse(error.data).success
  );
};

export const ectoErrorSchemaFormat = z.object({
  errors: z.record(z.string(), z.array(z.string())),
});

export const spexValidationErrorSchema = z.object({
  title: z.string(),
  detail: z.string(),
  source: z.object({
    pointer: z.string(),
  }),
});

export const spexValidationErrorResponseSchema = z.object({
  errors: z.array(spexValidationErrorSchema),
});
