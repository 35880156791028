import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import qs from "qs";

import type { RootState } from "../../../../store";
import { isAbsoluteUrl } from "../../../../store/common";

const rawBaseQuery = fetchBaseQuery({
  paramsSerializer: qs.stringify,
  // Being that we are using dynamic environment configs (./env/[env].json) and don't have a variable such as process.env.REACT_APP_API_URL
  // available to us at build time, we default this as an empty string here, then fetch the base API url in `dynamicBaseQuery`.
  baseUrl: "",
  // The default is `json`, but that falls apart when we have files and/or text/something responses
  responseHandler: "content-type",
});

const buildArgWithAuthHeaders = (
  arg: string | FetchArgs,
  token: string,
  //   buildingId: string
) => {
  const headersWithAuth = {
    authorization: `Bearer ${token}`,
  };

  return typeof arg === "string"
    ? { url: arg, headers: headersWithAuth }
    : {
        ...arg,
        headers: headersWithAuth,
      };
};

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (arg, api, extraOptions) => {
  const {
    auth: { auditCloudToken },
  } = api.getState() as RootState;

  const result = await rawBaseQuery(
    buildArgWithAuthHeaders(arg, auditCloudToken),
    api,
    extraOptions,
  );

  return result;
};

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (arg, api, extraOptions) => {
  const { config } = api.getState() as RootState;

  const originalUrl = typeof arg === "string" ? arg : arg.url;

  const shouldSkipCombiningUrls =
    isAbsoluteUrl(originalUrl) ||
    ["/api"].some((v) => originalUrl.startsWith(v));

  // If we've set an absolute URL, use that, otherwise use the API_URL from the config
  const finalUrl = shouldSkipCombiningUrls
    ? originalUrl
    : `${config.AUDIT_API_URL}${originalUrl}`;

  const adjustedArgs =
    typeof arg === "string"
      ? finalUrl
      : {
          ...arg,
          url: finalUrl,
        };

  return baseQuery(adjustedArgs, api, extraOptions);
};

export default dynamicBaseQuery;
