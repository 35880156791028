import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import { dynamicMiddleware, rootReducer } from "../..";
import { auditApi } from "../../../apps/audit/store/services/api";
import { useAppDispatch } from "../../hooks";

export function AuditMiddlewareInjector({ children }: { children: ReactNode }) {
  const [ready, setReady] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ready) return;

    rootReducer.inject(auditApi);
    dynamicMiddleware.addMiddleware(auditApi.middleware);

    // dispatching an action will fill out the state for the query hooks that get mounted and
    // avoid a console error on the initial pending query
    // this is necessary as `.inject()` doesn't have a store reference so we can't do this automatically
    // like it happens with regular ol' `replaceReducer` from redux
    dispatch({ type: "hydrate-injected-api-state" });

    setReady(true);
  }, [ready, dispatch]);

  if (!ready) return null;

  return <>{children}</>;
}
