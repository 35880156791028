import { createSlice } from "@reduxjs/toolkit";
import type { AnyAction } from "@reduxjs/toolkit";

import type { RootState } from "../../store";
import { api } from "../../store/api";
import type { Organization } from "../../store/services/api.generated";

import {
  clearOrganization,
  setAuditCloudToken,
  setAuthData,
  setOrganization,
} from "./actions";

/**
 * We need to setAuthClaims on initial login using an api matcher, and we need to also have an action to be used when
 * our baseQuery results in 401 when we attempt to refetch claims. This is a local util to consolidate the claims payload
 * being put in state
 * @param state - auth state
 * @param payload - an IdToken from claims
 */
const setAuthClaims = (
  state: { token: string; role: string },
  { payload }: AnyAction,
) => {
  state.token = payload?.__raw ?? "";
};

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: "",
    role: "",
    organization: {} as Organization,
    auditCloudToken: "",
  },
  reducers: {},
  extraReducers: (build) => {
    build.addCase(setAuthData, setAuthClaims);
    build.addCase(setOrganization, (state, { payload }) => {
      state.organization = payload;
    });
    build.addCase(clearOrganization, (state) => {
      state.organization = {} as Organization;
    });
    build.addCase(setAuditCloudToken, (state, { payload }) => {
      state.auditCloudToken = payload;
    });
    build.addMatcher(
      api.endpoints["organizationsShow"].matchFulfilled,
      (state, { payload }) => {
        state.organization = payload?.data ?? {};
      },
    );
  },
});

export default authSlice.reducer;

export const selectCurrentOrganizationId = (state: RootState) =>
  state.auth.organization.id;
export const selectCurrentOrganization = (state: RootState) =>
  state.auth.organization;
export const selectAuditCloudToken = (state: RootState) =>
  state.auth.auditCloudToken;
